/**
App.css contains all design details for components
**/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


/* App or common design */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* font and color scheme of body */
body{
  font-family: 'Poppins', sans-serif;
  color: #111B58 !important;
}

.input-group-text, .nav-link {
  color: #111B58;
}

.datatable table td {
  white-space: normal !important;
  text-align: left !important;
}

/* Button design */
.btn-primary, .btn-primary:disabled, .btn-check:checked+.btn-primary, .btn-check:active+.btn-primary, .btn-primary:active, .btn-primary.active, .show>.btn-primary.dropdown-toggle {
  background-color: #111B58;
}

/* text and background design */
.text-primary {
  color: #111B58!important;
}

.bg-primary {
  background-color: #111B58;
}

.link-primary {
  background-color: #111B58;
}

.badge-primary {
  color: #111B58;
  /*background-color: rgba(17, 27, 88, 0.2);*/
}

.container {
  max-width: 96%;;
}
.ulColoum{
  columns: 2;
  margin: 1px;
 }
 
 .liList {
   page-break-inside: avoid;
   break-inside: avoid;
   }
   
 h1{
     font-size: 45px;
   }
 
 h2{
     font-size: 35px;
   }
 
 h3{
     font-size: 25px;
   }
 
 p {
   font-size: 17px;
 }

 /* carousel design */
.carousel-control-prev-icon:after, .carousel-control-next-icon:after {
  color: black!important;
}

.multi-carousel-item {
  width: fit-content!important;
}

.carousel-control-next,
.carousel-control-prev {
  width: fit-content !important;
  position: inherit !important;
}

.carousel-control-next {
  margin-left: 15px
}

/* stepper design */
.stepper-completed .stepper-head-icon {
  background-color: #111B58;
}