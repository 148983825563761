body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.multi-carousel-inner {
  display: flex !important;
  justify-content: start !important;
  order: 2;
}

.carousel-control-prev-icon:after {
  font: var(--fa-font-solid);
  content: "\f338";
}

.carousel-control-prev {
  justify-content: start !important;
  order: 1;
}

.carousel-control-next {
  justify-content: end !important;
  order: 3;
}

.carousel-control-next,
.carousel-control-prev {
  width: fit-content !important;
  position: inherit !important;
}

.comment-form-button {
  font-size: 16px;
  padding: 8px 16px;
  background: rgb(59, 130, 246);
  border-radius: 8px;
  color: white;
}

.comment-form-button:hover:enabled {
  cursor: pointer;
  background: rgb(37, 99, 235);
}

.comment-form-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.comment-action:hover {
  text-decoration: underline;
}

.stepper-active .stepper-head-icon {
  height: 44px;
  width: 44px;
  font-size: 24px;
  margin-top: 14px!important;
  margin-bottom: 14px!important;
}
